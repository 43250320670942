// ANCHOR React
import React from 'react';

// ANCHOR Next
import Image from 'next/image';

// ANCHOR Base
import { styled } from 'baseui';
import { Block } from 'baseui/block';
import { Spinner, SIZE } from 'baseui/spinner';

// ANCHOR Constants
import { IMG_ALT, IMG_SOURCE } from './constants';

// ANCHOR Styles
import { BLOCK, LOGO, INNER_BLOCK } from './styles';

// ANCHOR Components
import { constant } from '../utils/constant';

const LyonLogo = styled(Image, LOGO);

export const FullPageLoader = constant(() => (
  <Block overrides={BLOCK}>
    <Block overrides={INNER_BLOCK}>
      <LyonLogo
        width="64"
        height="64"
        layout="fixed"
        src={IMG_SOURCE}
        alt={IMG_ALT}
      />
      <Spinner $size={SIZE.small} />
    </Block>
  </Block>
));
