import { BlockOverrides } from 'baseui/block';
import { BACKGROUND } from '@lyon/utils/theme/colors/background';

export const LOGO = {
  marginBottom: '16px',
};

export const BLOCK: BlockOverrides = {
  Block: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
      backgroundColor: BACKGROUND.backgroundContent,
    },
  },
};

export const INNER_BLOCK: BlockOverrides = {
  Block: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
};
