/* eslint-disable import/no-default-export */
// ANCHOR React
import React from 'react';

// ANCHOR Next
import dynamic from 'next/dynamic';

// ANCHOR Components
import { LyonAppHead } from '@lyon/components/head/LyonAppHead';
import { FullPageLoader } from '@lyon/components/full-page-loader';

const Lyon404 = dynamic<any>(
  () => (
    import('@lyon/components/404/Lyon404')
      .then((mod) => mod.Lyon404)
  ),
  {
    loading: () => <FullPageLoader />,
  },
);

const OPEN_GRAPH = {
  url: 'https://lyon.com.ph/',
  width: '1366',
  height: '768',
  image: './404.svg',
};

export default function ErrorPage() {
  return (
    <>
      <LyonAppHead
        title="Lyon | Not Found"
        description="Oops! This page doesn't exist."
        openGraph={OPEN_GRAPH}
      />
      <Lyon404 />
    </>
  );
}
