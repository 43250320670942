// ANCHOR React
import React from 'react';

// ANCHOR Next
import Head from 'next/head';

export interface IOpenGraphProps {
  url: string;
  image: string;
  width: string;
  height: string;
}

export interface IProps {
  title: string;
  description: string;
  openGraph?: IOpenGraphProps;
}

export function LyonAppHead({ title, description, openGraph }: IProps) {
  return (
    <Head>
      {/* Viewport for responsive web design */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0, viewport-fit=cover" />
      {/* Document Title */}
      <title>{ title }</title>
      {/* Meta Description */}
      <meta name="description" content={description} />
      <meta name="theme-color" content="#5B36C9" />
      {/* Web Manifest */}
      <link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/favicon/apple-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/favicon/apple-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/favicon/apple-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/favicon/apple-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-icon-180x180.png" />
      <link rel="icon" type="image/png" sizes="192x192" href="/favicon/android-icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#5B36C9" />
      <meta name="msapplication-TileImage" content="/favicon/ms-icon-144x144.png" />
      {
        openGraph && (
          <>
            <meta property="og:type" content="website" />
            <meta property="og:url" content={openGraph.url} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={openGraph.image} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content="Lyon" />
            <meta property="og:locale" content="en_PH" />
            <meta property="og:image:width" content={openGraph.width} />
            <meta property="og:image:height" content={openGraph.height} />
          </>
        )
      }
    </Head>
  );
}
